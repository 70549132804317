import { createAction } from '../../util/createCrudAction';
import { Dispatch } from '../../util/types';
import { Schemas } from '../../schemas';
import { setWaiting } from '../ui/loading';

const baseType = 'INSURANCE';

interface Contract {
  verzekering: boolean;
}

interface Vehicle {
  id: number;
  kenteken?: string;
  contract?: Contract;
}

export const readInsurance = (vehicle: Vehicle) => (dispatch: Dispatch) => {
  return dispatch({
    ...{ payload: { vehicle } },
    ...createAction('INSURANCE', Schemas.INSURANCE, `/voertuig/${vehicle.id}/verzekering`, 'GET', {
      _vehicle: vehicle.id,
    }),
  });
};

export const readInsuranceCard = (id: number, vehicle?: Vehicle) => (dispatch: Dispatch) => {
  if (
    !vehicle ||
    (vehicle.contract && vehicle.contract.verzekering && (vehicle.kenteken?.replace(/\s/g, '')?.length || 0) >= 5)
  ) {
    return dispatch({
      payload: {
        id,
      },
      ...createAction('INSURANCE_CARD', null, `/voertuig/groenekaart/${id}`, 'GET'),
    });
  }
};

export const sendInsuranceCard = (id: number) => (dispatch: Dispatch) => {
  dispatch(setWaiting(true));

  return (
    dispatch({
      // type: "sendInsuranceCard",
      payload: { id },
      ...createAction(
        'INSURANCE_CARD',
        null,
        `/voertuig/groenekaart-mailen/${id}`,
        'GET',
        undefined,
        undefined,
        undefined,
        'SEND'
      ),
    }) as unknown as Promise<any>
  ).then(
    (res) => {
      dispatch(setWaiting(false));
      return res;
    },
    (err) => {
      dispatch(setWaiting(false));
      throw err;
    }
  );
};

export const readEUDamageForm = (vehicle: Vehicle) => (dispatch: Dispatch) => {
  return dispatch({
    ...{ payload: vehicle },
    ...createAction(`${baseType}_EU_FORM`, null, `/voertuig/${vehicle.id}/verzekering/schadeformhulp`, 'GET'),
  });
};
